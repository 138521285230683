<template>
	<div class="main-contents info_mf">
		<div class="tit">하이프로 관리자 등록<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1);"/></div>
		<div class="sub-tit"><p>하이프로 관리자 등록</p></div>
		<div class="enter_inform">
			<div class="form">
				<div class="label">성명</div><div class="list"><InputComp v-model="admInfo.mberNm" maxlengh='50' title="성명" :rules="{'required':true}" /></div>
			</div>
			<div class="form" :class="!admInfo.admMberSeq ? 'wd01' : ''">
			<!-- <div class="form wd01"> -->
				<div class="label">담당자 아이디</div><div class="list"><InputComp v-model="admInfo.admId" maxlengh='50' title="아이디" :rules="{'required':true, 'minLength':4, 'maxLength':16}" :disabled="admInfo.admMberSeq ? true : false" /></div>
				<div class="btn" v-if="!admInfo.admMberSeq" @click="idDupChk()">아이디 중복확인</div>
				<!-- <div class="btn" @click="idDupChk()">아이디 중복확인</div> -->
			</div>
			<div class="form">
				<div class="label">비밀번호</div><div class="list"><InputComp type="password" v-model="admInfo.password" maxlength="16" title="비밀번호" :rules="{'required':pwdReq, 'minLength':8, 'maxLength':16}" /></div>
			</div>
			<div class="form">
				<div class="label">비밀번호 확인</div><div class="list"><InputComp type="password" v-model="admInfo.passwordCfm" maxlength="16" title="비밀번호 확인" :rules="{'required':pwdReq,'minLength':8,'maxLength':16,'func' : {rule:same, args:admInfo.password}}"/></div>
			</div>
			<div class="form">
				<div class="label">이메일</div><div class="list"><InputComp v-model="admInfo.email" title="이메일" :rules="{'required':selfModify}" /></div>
			</div>
			<div class="form">
				<div class="label">휴대폰번호</div><div class="list"><InputComp type="cell"  v-model="admInfo.hpNo" title="휴대폰번호" :rules="{'required':selfModify}" /></div>
			</div>
			<div class="form industry">
				<div class="label">직급</div><InputComp v-model="admInfo.positionNm" />
				<div class="label emp">담당분야</div>
				<div class="list">
					<SelectComp cdId="PRO101" filter="0" v-model="admInfo.mberDivCd" title="담당분야 선택" :rules="{'required':true}" />
				</div>
			</div>
			<div class="form">
				<div class="label">성별</div>
				<SelectComp type="radio" v-model="admInfo.gender" list="F:여,M:남" separator="/" />
			</div>
		</div>
		<div class="select_btn">
			<div class="btn_01" @click="cancel()">취소</div>
			<div class="btn_02" v-if="!admInfo.admMberSeq" @click="setAdminInfo()">등록</div>
			<div class="btn_02" style="background-color: #FF681D;" v-else @click="setAdminInfo()">수정완료</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			admInfo : {
				gender : 'F',
			},

			pwdReq : this.$route.params.admMberSeq ? false : true,
			selfModify : false,
			idDupChkRes : false,
		}
	},

	mounted() {
		if(this.$route.params.admMberSeq) {
			this.admInfo.admMberSeq = this.$route.params.admMberSeq;
			this.getAdmMberInfo();
		}
	},

	methods : {
		setAdminInfo() {
			//console.log('this.admInfo', this.admInfo);

			if(!this.idDupChkRes && !this.admInfo.admMberSeq) {
				alert('아이디 중복확인을 하지 않았습니다.');
				return false;
			}

			var param = this.admInfo;
			this.$.httpPost('/api/mem/adm/pro/setAdminInfo', param)
				.then(() => {
					var text = '등록';
					if(this.admInfo.admMberSeq) text = '수정';

					alert('하이프로 관리자 ' + text + '이 완료 되었습니다.');
					this.$router.push('MEM970M01');

				}).catch(this.$.httpErrorCommon);
		},

		// 비밀번호/비밀번호확인 일치 체크
		same(val, args){
			return val == (args || '') || '비밀번호와 일치하지 않습니다.';
		},

		idDupChk() {
			if(!this.admInfo.admId) {
				alert('아이디를 입력하지 않았습니다.');
				return false;
			}

			this.$.httpPost('/api/mem/adm/pro/idDupChk', {admId:this.admInfo.admId})
				.then(() => {
					this.idDupChkRes = true;

				}).catch(this.$.httpErrorCommon);
		},

		getAdmMberInfo() {
			this.$.httpPost('/api/mem/adm/pro/getAdmMberInfo', {admMberSeq:this.admInfo.admMberSeq})
				.then((res) => {
					//console.log('getAdmMberInfo RESULT', res);
					this.admInfo = res.data;

				}).catch(this.$.httpErrorCommon);
		},

		cancel() {
			if(confirm('취소하시겠습니까?')) {
				this.$router.go(-1);
			}
		}
	}
}
</script>